body {
  background-color: #1F1F1F;
  font-family: "Nunito", sans-serif;
  color: white;
}

section.main {
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#logo {
  width: 50px;
  margin: 15px;
}
.navbar {
  padding-bottom: 5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;

}

.navbar-nav {
  align-items: center;
}

.navbar-dark .navbar-toggler {
  border: none;
  padding: 10px;
}

.black-out-button {
  background: linear-gradient(145deg, #212121, #1c1c1c);
  box-shadow: 5px 5px 5px #1a1a1a, -5px -5px 5px #242424;
  border-radius: 10px;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    background: #1F1F1F;
    box-shadow: inset -5px -5px 10px 5px #242424, inset 5px 5px 10px 5px #1A1A1A;
}

.black-in-button, .black-out-button:hover {
  box-shadow: inset -5px -5px 10px 5px #242424, inset 5px 5px 10px 5px #1A1A1A;
  border-radius: 10px;
}

/* .nav-link.in-button, .nav-link.out-button:hover {
  height: 55px;
  width: 155px;
} */

.navbar-brand {
  border-radius: 50%;
  height: auto;
  width: auto;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}


.navbar-dark .navbar-nav .nav-link {
    margin: 20px;
    height: 50px;
    width: 150px;
    text-align: center;
    font-size: 23px;
    padding-top: 7px;
    color: white;
    transition: all 0.12s cubic-bezier(0.47, 0, 0.745, 0.715);
}

.navbar-nav .nav-link.black-in-button {
  height: 55px;
  width: 155px;
  padding-top: 10px;
}

button.navbar-toggler.out-button:hover {
  width: auto;
}

h2.home {
  font-weight: 700;
  font-size: 4rem;
}
h1.title {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 3rem;
}

h1.home {
  text-align: center;
  font-weight: 900;
  font-size: 6rem;
  text-shadow: 8px 4px 0px #a8a8ce;
}

@supports (-webkit-text-stroke:  10px #1F1F1F) {
  h1.home {
    -webkit-text-stroke: 4px #1F1F1F;
    -webkit-text-fill-color: white;
  }
}

.home {
  padding-bottom: 10%;
}

.row.home .black-in-button {
  padding: 35px;
  text-align: center;
  font-size: 1.5rem;
  min-height: 150px;
  max-width: 450px;
  transition: all 0.12s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.row.home {
  width: 100%;
}

.about-text {
    text-align: center;
    /* height: 450px; */
    display: table-cell;
    vertical-align: middle;
    padding: 30px;
    max-width: 600px;
    font-size: 20px;
}


@media only screen and (max-width: 550px) {
  h2.home {
    font-size: 2rem;
  }
  h1.home {
    font-size: 3rem;
    -webkit-text-stroke: 2px #1F1F1F;
    text-shadow: 5px 1px 0px #a8a8ce;
  }
  .home {
    padding-bottom: 20%;
  }
  .row.home .in-button {
    font-size: 1.25rem;
  }
  .about-text {
    font-size: 16px;
  }
}

.row.home {
  justify-content: flex-end;
}

.blue-in-button {
  background-color: #E6E6F5;
  color: #1F1F1F;
  background: linear-gradient(0deg, #E6E6F5, #E6E6F5);
  box-shadow: inset -5px -5px 10px 5px rgba(255, 255, 255, 0.05), inset 5px 5px 10px 5px rgba(106, 107, 131, 0.25);
  border-radius: 10px;
}

#headshot {
  max-width: 200px;
  border-radius: 10px;
}

.heady, .tech {
  width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  max-height: 325px;
  margin-bottom: 40px;
}

div.col-12.col-lg-4 {
  display: flex;
  justify-content: center;
}

.blue-in-button, .blue-out-button:hover {
  box-shadow: inset 5px 5px 10px #aaaad09c, inset -5px -5px 10px #e6e6ff;
  background-color: #c8c8f5;
}

p.blue-in-button {
  padding: 10px;
  min-width: 200px;
  border-radius: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  transition: all 0.12s cubic-bezier(0.47, 0, 0.745, 0.715);
}


.row.about, .row.project {
  align-items: center;
}

div.col-lg-12 {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 1000px) {
  .heady {
    margin-bottom: 0px;
  }
}

.pink-in-button, .pink-out-button:hover {
  background: #EDE6F5;  
  box-shadow: inset 5px 5px 10px #c9c4d0,
            inset -5px -5px 10px #ffffff;
  border-radius: 10px;
  color: #1F1F1F;
  transition: all 0.12s cubic-bezier(0.47, 0, 0.745, 0.715);
}

.pink-out-button {
  background: linear-gradient(145deg, #fef6ff, #d5cfdd);
  box-shadow:  5px 5px 5px #c9c4d0,
             -5px -5px 5px #ffffff;
  border-radius: 10px;
  color: #1F1F1F;
}

div.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  text-align: center;
  padding: 30px;
  max-width: 500px;
}

.contact-link {
  height: 50px;
  width: 120px;
  text-align: center;
  margin: 20px;
  padding-top: 12px;
  text-decoration: none;
}

div.contact h4 {
  margin-bottom: 30px;
}

#linkedin, #github, #email {
  width: 25px;
  margin-left: 5px;
}

#live {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.blue-out-button {
  background: linear-gradient(145deg, #f6f6ff, #cfcfdd);
  box-shadow:  5px 5px 10px #c4c4d0,
             -5px -5px 10px #ffffff;
  border-radius: 10px;
  cursor: pointer;

}

a.blue-out-button.logo-link img{
  width: 45px;
}

img.project-logo {
  width: 40px;
  margin-left: 10px;
}

#live {
  width: 40px;
}

a.blue-out-button.logo-link {
  width: 60px;
}

.project-card {
  padding: 30px;
  height: 350px;
  width: 300px;
  min-width: 300px;
  position: relative;
}

div.blue-out-button.link {
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  text-decoration: none;
  color:#1a1a1a;
  width: 100%;
  display: block;
  transition: all 0.12s cubic-bezier(0.47, 0, 0.745, 0.715);
    margin: auto;
    width: fit-content;
    position: relative;
    top: 15px;
    max-width: 90%;
}

div.projects {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;

}

.short-descrip {
  height: 125px;
  padding: 15px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.blue-out-button.logo-link.left {
    right: 50px;
    position: absolute;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;

}

.blue-out-button.logo-link.right {
    left: 50px;
    position: absolute;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

div.descrip {
  padding: 30px;
  margin: 30px;
  text-align: center;
}

a.live, a.code {
  display: inline-block;
    height: 60px;
    width: 150px;
    text-align: center;
    margin: 20px;
    padding-top: 12px;
    text-decoration: none;
    margin-bottom: 30px;
}

div.big-div {
  max-width: 800px;
  margin-bottom: 2rem;
}

a.code {
  float: left;
  margin-left: 15%;
}

a.live {
  float: right;
  margin-right: 15%;
}

@media only screen and (max-width: 600px) {
  a.live, a.code {
    float: none;
    margin: 0px;
    margin-bottom: 30px;
  }

  div.big-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.tech-stack {
    padding: 30px;
    width: 80%;
    margin-bottom: 30px;
    text-align: center;
}

.tech-title {
    width: 80%;
    height: 50px;
    text-align: center;
    padding-top: 13px;
    margin-bottom: 30px;
    font-weight: 700;
}

div.tech {
  margin-top: 50px;
  width: 250px;
  max-height: none;
}

p.individual-tech {
  margin: 6px;
}

.loader-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.loader {
  width: 200px;
  height: 130px;
  visibility: none;
  /* animation-duration: 3s;
  animation-iteration-count: 1;
  animation-name: bounce; */
  animation: bounce 2s cubic-bezier(0.280, 0.840, 0.420, 1) 1 forwards;
  /* animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); */
}

@keyframes bounce {
    0%   { transform: scale(1,1)      translateY(0); visibility: visible;}
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-100px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); opacity: 1; }
    100% { transform: scale(1,1)      translateY(0); opacity: 0; }
}

body{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
footer{
    padding-top: 30px;
    margin-top: auto;
}


/* .black-out-button {
  background: linear-gradient(145deg, #86d7b1, #71b595);
box-shadow:  5px 5px 5px #6aab8c,
             -5px -5px 5px #90e7be;
} */

div.skill-container {
  max-width: 125px;
  margin: 1rem;
  max-height: 150px;
}

img.skill {
  width: 100%;
  max-height: 100%;
}

div.row.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

div.row.skills h2 {
  text-align: center;
}
h1.top {
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 600;
}

@media only screen and (max-width: 400px) {
  div.skill-container {
  max-width: 100px;
}
section.main {
  padding-left: 5%;
  padding-right: 5%;
}
div.descrip {
  padding: 15px;
}
}